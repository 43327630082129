import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { doc, setDoc, onSnapshot, collection, query, where, getDocs } from "firebase/firestore";
import { auth, db } from './firebaseDatabase';
import axios from 'axios';
import './Dashboard.css';

Modal.setAppElement('#root');

function Dashboard() {
  const [multiplier, setMultiplier] = useState(1.00);
  const [isRunning, setIsRunning] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [displayAmount, setDisplayAmount] = useState("0.00");
  const [betPlaced, setBetPlaced] = useState(false);
  const [betHistory, setBetHistory] = useState([]);
  const [multiplierHistory, setMultiplierHistory] = useState([]);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [modalAmount, setModalAmount] = useState(0);
  const [btcAddress, setBtcAddress] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [viewType, setViewType] = useState('myBets'); // Set default to 'myBets'

  const [allUsersBets, setAllUsersBets] = useState([]);

  const targetMultiplier = useRef(0);
  const hasCashedOut = useRef(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeBalance = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            setBalance(docSnap.data().balance || 0);
          }
        });

        const betsQuery = query(collection(db, "users", user.uid, "bets"));
        const betsSnapshot = await getDocs(betsQuery);
        const userBets = betsSnapshot.docs.map(doc => doc.data());
        setBetHistory(userBets);

        return () => unsubscribeBalance();
      }
    });
    
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const socket = new WebSocket('wss://cryptofrostbackend-1.onrender.com');

    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'initial_multiplier_history') {
        setMultiplierHistory(data.multiplierHistory);
        setAllUsersBets([]); // Clear generated data
      }

      if (data.multiplier !== undefined) {
        setMultiplier(data.multiplier);
        setIsRunning(true);

        if (betPlaced) {
          setDisplayAmount((betAmount * data.multiplier).toFixed(2));
        }
      }

      if (data.countdown !== undefined) {
        setCountdown(data.countdown);
        setIsRunning(false);
      }

      if (data.type === 'round_end') {
        targetMultiplier.current = data.multiplier;
        endRound();

        setMultiplierHistory(prev => {
          const updatedHistory = [...prev, targetMultiplier.current];
          return updatedHistory.length > 50 ? updatedHistory.slice(-50) : updatedHistory;
        });
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => socket.close();
  }, [betPlaced, betAmount]);

  const handlePlaceBet = async () => {
    const user = auth.currentUser;
    if (!user) {
        setShowLoginWarning(true);
        setTimeout(() => setShowLoginWarning(false), 5000);
        return;
    }

    if (betAmount > 0 && betAmount <= balance && countdown > 0 && !betPlaced) {
        try {
            const newBalance = balance - betAmount;
            setBalance(newBalance);

            const newBetEntry = {
                username: user.email,
                betAmount: betAmount,
                cashoutAmount: 0,
                multiplier: 1.00,
                result: "pending",
                timestamp: new Date(),
            };

            const betDocRef = doc(collection(db, "users", user.uid, "bets"));
            await setDoc(betDocRef, newBetEntry);

            const socket = new WebSocket('wss://cryptofrostbackend-1.onrender.com');
            socket.onopen = () => {
                socket.send(JSON.stringify({
                    type: 'place_bet',
                    userId: user.uid,
                    betAmount: betAmount,
                }));
            };

            setBetHistory(prev => [...prev, newBetEntry]);
            setDisplayAmount(betAmount.toFixed(2));
            setBetPlaced(true);
            
        } catch (error) {
            console.error("Error placing bet:", error);
            alert("An error occurred while placing the bet. Please try again.");
        }
    } else {
        alert("Cannot place bet: Check your balance, bet amount, or countdown status.");
    }
  };

  const handleCashout = async (multiplierValue = multiplier) => {
    if (isRunning && betPlaced && parseFloat(displayAmount) > 0 && !hasCashedOut.current) {
      const cashoutAmount = parseFloat((betAmount * multiplierValue).toFixed(2));
      setBalance(balance + cashoutAmount);

      const updatedBetHistory = betHistory.map(bet => {
        if (bet.result === "pending") {
          return { ...bet, cashoutAmount: cashoutAmount, result: "won", multiplier: multiplierValue };
        }
        return bet;
      });
      
      setBetHistory(updatedBetHistory);

      const user = auth.currentUser;
      if (user) {
        const betRef = query(collection(db, "users", user.uid, "bets"), where("result", "==", "pending"));
        const betsSnapshot = await getDocs(betRef);
        if (!betsSnapshot.empty) {
          await setDoc(betsSnapshot.docs[0].ref, {
            cashoutAmount: cashoutAmount,
            result: 'won',
            multiplier: multiplierValue,
          }, { merge: true });
        }
        
        await setDoc(doc(db, "users", user.uid), { balance: balance + cashoutAmount }, { merge: true });
      }
      
      setDisplayAmount("0.00");
      setBetPlaced(false);
      hasCashedOut.current = true;
    }
  };

  const endRound = () => {
    setIsRunning(false);
    setCountdown(10);
    setMultiplier(1.00);
    setDisplayAmount("0.00");

    if (betPlaced && !hasCashedOut.current) {
      setBetHistory(prevHistory =>
        prevHistory.map(bet =>
          bet.result === "pending"
            ? { ...bet, result: "lost", cashoutAmount: 0, multiplier: multiplier }
            : bet
        )
      );

      const user = auth.currentUser;
      if (user) {
        const betRef = query(collection(db, "users", user.uid, "bets"), where("result", "==", "pending"));
        getDocs(betRef).then(betsSnapshot => {
          if (!betsSnapshot.empty) {
            setDoc(betsSnapshot.docs[0].ref, {
              result: "lost",
              cashoutAmount: 0,
              multiplier: multiplier,
            }, { merge: true });
          }
        });
      }
    }

    setBetPlaced(false);
    hasCashedOut.current = false;
  };

  const handleDeposit = () => {
    const user = auth.currentUser;
    if (!user) {
      setShowLoginWarning(true);
      setTimeout(() => setShowLoginWarning(false), 5000);
      return;
    }

    setIsDepositModalOpen(true);
  };

  const handleWithdraw = () => {
    const user = auth.currentUser;
    if (!user) {
      setShowLoginWarning(true);
      setTimeout(() => setShowLoginWarning(false), 5000);
      return;
    }

    setIsWithdrawModalOpen(true);
  };

  const closeDepositModal = () => {
    setIsDepositModalOpen(false);
    setModalAmount(0);
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
    setModalAmount(0);
    setBtcAddress('');
  };

  const handleDepositSubmit = async () => {
    const depositAmount = parseFloat(modalAmount);
    if (depositAmount >= 50) {
      try {
        const user = auth.currentUser;
        if (user) {
          const orderId = `order_${new Date().getTime()}`;

          const response = await axios.post('https://cryptofrostbackend-1.onrender.com/api/payment', {
            userId: user.uid,
            price_amount: depositAmount,
            price_currency: 'USD',
            pay_currency: 'BTC',
            order_id: orderId,
            order_description: 'Deposit for user ' + user.uid,
          });

          const { invoice_url } = response.data;

          if (invoice_url) {
            window.location.href = invoice_url;
          } else {
            alert('Failed to initiate payment. Please try again.');
          }
        }
      } catch (error) {
        console.error('Payment error:', error);
        alert('Payment failed. Please try again.');
      }
    } else {
      alert('Minimum deposit amount is $50.');
    }
  };

  const handleWithdrawSubmit = async () => {
    const withdrawAmount = parseFloat(modalAmount);
    const user = auth.currentUser;

    if (user && withdrawAmount >= 1 && withdrawAmount <= 1000 && withdrawAmount <= balance && btcAddress) {
        try {
            const idToken = await user.getIdToken(true);

            const response = await axios.post('https://cryptofrostbackend-1.onrender.com/api/withdraw', {
                userId: user.uid,
                amount: withdrawAmount,
                btcAddress: btcAddress,
            }, {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            });

            setBalance(response.data.balance);
            alert(response.data.message);

            const docRef = doc(db, "users", user.uid);
            await setDoc(docRef, { balance: response.data.balance }, { merge: true });

          } catch (error) {
            console.error('Withdrawal error:', error.response ? error.response.data : error.message);
            alert('Withdrawal failed. Please try again.');
          }
    } else {
        alert('Invalid withdraw amount or BTC address. Ensure the amount is between $1 and $1,000, not more than your balance, and that you entered a valid BTC address.');
    }
    closeWithdrawModal();
  };

  // Function to determine the styles for each multiplier
  const getMultiplierStyles = (multiplier) => {
    if (multiplier >= 1.00 && multiplier < 2.00) {
      return {
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // transparent background
        borderColor: 'rgba(255, 255, 255, 0.3)',      // white border
      };
    } else if (multiplier >= 2.00 && multiplier < 10.00) {
      return {
        backgroundColor: 'rgba(255, 0, 0, 0.1)', // red background
        borderColor: 'rgba(255, 0, 0, 0.3)',      // red border
      };
    } else if (multiplier >= 10.00 && multiplier <= 100.00) {
      return {
        backgroundColor: 'rgba(255, 215, 0, 0.1)', // gold background
        borderColor: 'rgba(255, 215, 0, 0.3)',      // gold border
      };
    }
    return {}; // Default styles (if needed)
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-title">GAME AREA</div>
        <div className="dashboard-balance-card">
          Balance: ${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>
        <button className="deposit-button" onClick={handleDeposit}>Deposit</button>
        <button className="withdraw-button" onClick={handleWithdraw}>Withdraw</button>
      </div>
      <div className="dashboard-middle">
        <div className="main-content">
          <div className="main-message-card">
            <h1 className={`main-message ${isAnimating ? 'animate' : ''}`}>
              {(multiplier || 1.00).toFixed(2)}x
            </h1>
          </div>
        </div>
      </div>
      {countdown > 0 && !isRunning && (
        <div className="countdown">
          Next round in {countdown}...
        </div>
      )}
      <div className="dashboard-bottom">
        <div className="bet-controls">
          <button className="bet-button" onClick={() => setBetAmount(1.00)} disabled={betPlaced || isRunning || countdown === 0}>1.00</button>
          <button className="bet-button" onClick={() => setBetAmount(2.00)} disabled={betPlaced || isRunning || countdown === 0}>2.00</button>
          <button className="bet-button" onClick={() => setBetAmount(5.00)} disabled={betPlaced || isRunning || countdown === 0}>5.00</button>
          <button className="bet-button" onClick={() => setBetAmount(10.00)} disabled={betPlaced || isRunning || countdown === 0}>10.00</button>
          <button className="bet-button" onClick={() => setBetAmount(50.00)} disabled={betPlaced || isRunning || countdown === 0}>50.00</button>
          <button className="bet-button" onClick={() => setBetAmount(100.00)} disabled={betPlaced || isRunning || countdown === 0}>100.00</button>
          <input
            type="number"
            className="custom-amount-input"
            value={betAmount || ""}
            onChange={(e) => setBetAmount(Math.max(0, Number(e.target.value)))}
            placeholder="Custom Amount"
            disabled={betPlaced || isRunning || countdown === 0}
          />
          <button
            className="place-bet-button"
            onClick={handlePlaceBet}
            disabled={betPlaced || isRunning || betAmount <= 0 || betAmount > balance || countdown === 0}
          >
            Place BET
          </button>
          <input
            type="number"
            className="cashout-amount"
            value={displayAmount || ""}
            readOnly
          />
          <button
            className="cashout-button"
            onClick={() => handleCashout()}
            disabled={!betPlaced || parseFloat(displayAmount) <= 0 || !isRunning}
          >
            Cashout
          </button>
        </div>
        <div className="bottom-sections">
          <div className="bet-history">
            <div className="bet-history-header">
              Bet History
              <div className="bet-history-controls">
                <button 
                  onClick={() => setViewType('allUsers')} 
                  className={`tab-button ${viewType === 'allUsers' ? 'active' : ''}`}
                >
                  All Users
                </button>
                <button 
                  onClick={() => setViewType('myBets')} 
                  className={`tab-button ${viewType === 'myBets' ? 'active' : ''}`}
                >
                  My Bets
                </button>
              </div>
            </div>



            <div className="half-section">
              {viewType === 'myBets' && Array.isArray(betHistory) && betHistory.length > 0 ? (
                betHistory.map((entry, index) => (
                  <div key={index} className="history-item">
                    {entry.username ? entry.username : 'N/A'} 
                    &nbsp;&nbsp; bet: ${entry.betAmount !== undefined && entry.betAmount !== null ? entry.betAmount.toFixed(2) : 'N/A'} 
                    &nbsp;&nbsp; cashout: {entry.cashoutAmount !== undefined && entry.cashoutAmount !== null ? `$${entry.cashoutAmount.toFixed(2)}` : 'N/A'}
                    &nbsp;&nbsp; result: {entry.result}
                    &nbsp;&nbsp; multiplier: {entry.multiplier ? entry.multiplier.toFixed(2) : 'N/A'}
                  </div>
                ))
              ) : viewType === 'allUsers' ? (
                <div className="no-history">Coming Soon</div>
              ) : (
                <div className="no-history">No Bet History</div>
              )}
            </div>
          </div>
          <div className="multiplier-history">
            <div className="multiplier-history-header">Multiplier History</div>
            <div className="half-section">
              {multiplierHistory.length > 0 ? multiplierHistory.map((mult, index) => (
                <div 
                  key={index} 
                  className="history-item" 
                  style={getMultiplierStyles(mult)}
                >
                  {mult.toFixed(2)}x
                </div>
              )) : <div className="no-history">No Multiplier History</div>}
            </div>
          </div>
        </div>
      </div>
      {showLoginWarning && (
        <div className="login-warning">
          <p>Please log in to use this feature!</p>
        </div>
      )}
      <Modal
        isOpen={isDepositModalOpen}
        onRequestClose={closeDepositModal}
        contentLabel="Deposit Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Deposit</h2>
        <input
          type="number"
          value={modalAmount || ""}
          onChange={(e) => setModalAmount(Math.max(0, Number(e.target.value)))}
          placeholder="Enter deposit amount"
        />
        <button onClick={handleDepositSubmit}>Submit</button>
        <button onClick={closeDepositModal}>Cancel</button>
      </Modal>

      <Modal
        isOpen={isWithdrawModalOpen}
        onRequestClose={closeWithdrawModal}
        contentLabel="Withdraw Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Withdraw</h2>
        <input
          type="number"
          value={modalAmount || ""}
          onChange={(e) => setModalAmount(Math.max(0, Number(e.target.value)))}
          placeholder="Enter withdraw amount"
        />
        <input
          type="text"
          value={btcAddress || ""}
          onChange={(e) => setBtcAddress(e.target.value)}
          className="btc-address-input"
          placeholder="Enter BTC address"
        />
        <button onClick={handleWithdrawSubmit}>Submit</button>
        <button onClick={closeWithdrawModal}>Cancel</button>
      </Modal>
    </div>
  );
}

export default Dashboard;
