import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { FaTelegramPlane } from 'react-icons/fa'; // Importing the Telegram icon from react-icons

function Sidebar() {
  const [activeUsers, setActiveUsers] = useState(() => {
    // Retrieve the active users number from localStorage if available
    const storedActiveUsers = localStorage.getItem('activeUsers');
    return storedActiveUsers ? parseInt(storedActiveUsers, 10) : null;
  });

  const generateActiveUsers = () => {
    return Math.floor(Math.random() * 3000) + 1000;
  };

  const generateRandomInterval = () => {
    const min = 20 * 60 * 1000; // 20 minutes in milliseconds
    const max = 35 * 60 * 1000; // 35 minutes in milliseconds
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    if (activeUsers === null) {
      const initialActiveUsers = generateActiveUsers();
      setActiveUsers(initialActiveUsers);
      localStorage.setItem('activeUsers', initialActiveUsers); // Store the new active users number in localStorage
    }

    const intervalId = setInterval(() => {
      const newActiveUsers = generateActiveUsers();
      setActiveUsers(newActiveUsers);
      localStorage.setItem('activeUsers', newActiveUsers); // Update localStorage with the new number
    }, generateRandomInterval());

    return () => clearInterval(intervalId);
  }, [activeUsers]);

  return (
    <div className="sidebar">
      <h1 className="sidebar-header">CRYPTO FROST</h1>
      <hr className="sidebar-divider" />
      <ul>
        <li className="sidebar-item">
          <a href="/" className="sidebar-link">
            <div className="sidebar-item-content">GAME AREA</div>
          </a>
        </li>
        <li className="sidebar-item">
          <a href="/profile" className="sidebar-link">
            <div className="sidebar-item-content">PROFILE</div>
          </a>
        </li>
        <li className="sidebar-item">
        <div className="sidebar-item-content active-users">Active Users 3456</div>
        </li>
        <li className="sidebar-item">
          <a href="https://t.me/cryptofrostcasino" className="sidebar-link" target="_blank" rel="noopener noreferrer">
            <div className="sidebar-item-content Telegram-link">
              <FaTelegramPlane className="telegram-icon" />
              Join our Telegram
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
