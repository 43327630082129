import React from 'react';
import './App.css';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Profile from './Profile';
import MobileDetection from './MobileDetection';
import './Stars.css'; // Import the CSS for the starry background

function App() {
  const appStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    background: 'radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)',
    overflow: 'hidden',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
    backgroundColor: 'rgba(255, 255, 255, 0)',
   
 
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
  };

  const renderMainContent = () => {
    const path = window.location.pathname;
    switch (path) {
      case '/profile':
        return <Profile />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <MobileDetection>
      <div className="app" style={appStyle}>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="container" style={containerStyle}>
          <Sidebar />
          {renderMainContent()}
        </div>
      </div>
    </MobileDetection>
  );
}

export default App;
