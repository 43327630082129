import React from 'react';

const MobileDetection = ({ children }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh', 
          textAlign: 'center', 
          backgroundColor: '#000', 
          color: '#fff', 
          padding: '20px',
          boxSizing: 'border-box'
        }}>
        <div>
          <h1>Mobile Version Coming Soon</h1>
          <p>Please use a desktop or laptop to access this site.</p>
        </div>
      </div>
    );
  }

  return children;
};

export default MobileDetection;
