import React, { useState, useEffect } from 'react';
import { auth, db } from './firebaseDatabase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, onSnapshot } from 'firebase/firestore';
import './Profile.css';

const Profile = () => {
  const [username, setUsername] = useState('');
  const [activeTab, setActiveTab] = useState('transactions');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        const userDocRef = doc(db, 'users', user.uid);

        const unsubscribeBalance = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUsername(userData.username);
          }
        });

        fetchUserTransactions(user.uid);

        return () => unsubscribeBalance();
      } else {
        setIsLoggedIn(false);
        localStorage.removeItem('userId');
        localStorage.removeItem('isLoggedIn');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserTransactions = (uid) => {
    const transactionsQuery = query(collection(db, 'users', uid, 'transactions'));
    onSnapshot(transactionsQuery, (snapshot) => {
      const transactionsList = snapshot.docs.map(doc => doc.data());
      setTransactions(transactionsList);
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Login successful!');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login failed. Please check your credentials and try again.');
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        username,
        email,
        balance: 0, 
      });

      // Initialize the transactions collection
      await setDoc(doc(db, 'users', user.uid, 'transactions', 'init'), {});

      alert('Sign-up successful! You can now log in.');
      setShowSignUp(false); 
    } catch (error) {
      console.error('Sign-up error:', error);
      setErrorMessage(error.message);
    }
  };

  const handleSignOut = () => {
    auth.signOut();
    alert('You have been signed out.');
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'transactions':
        return (
          <div className="tab-content">
            <h3>Transactions</h3>
            {transactions.length > 0 ? (
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.type}</td>
                      <td>{transaction.amount}</td>
                      <td>{transaction.date}</td>
                      <td>{transaction.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No recent transactions</p>
            )}
          </div>
        );
      case 'howToPlay':
        return (
          <div className="tab-content">
            <h3>How to Guide</h3>
            <h4>1. How to Deposit Using BTC</h4>
            <ol>
              <li><strong>Create an Account or Log In:</strong> Before making any deposits, you must have an account on Crypto Frost. If you don't have an account, click on the "Sign Up" button and follow the prompts to create one. If you already have an account, click on "Login" and enter your credentials.</li>
              <li><strong>Navigate to the Deposit Section:</strong> Once logged in, navigate to the “Deposit” button. Click on it to proceed.</li>
              <li><strong>Select BTC as Your Payment Method:</strong> A deposit form will appear.</li>
              <li><strong>Enter Deposit Amount:</strong> Enter the amount you wish to deposit. Ensure that the amount meets the minimum deposit requirement, which is typically around $50 due to network fees. Double-check your entered amount before proceeding.</li>
              <li><strong>Click on Submit:</strong> After filling out the required information, click on "Submit" and wait patiently for the BTC payment link.</li>
              <li><strong>Transfer BTC to the Generated Address:</strong> Open your Bitcoin wallet and transfer the exact deposit amount to the generated address. Wait for the transaction to be confirmed on the blockchain. This may take a few minutes.</li>
              <li><strong>Confirm Deposit:</strong> Once the transaction is confirmed, the deposit will reflect in your account balance. You can now use the deposited funds to play games.</li>
            </ol>

            <h4>2. How to Play the Game</h4>
            <ol>
              <li><strong>Start a New Game Round:</strong> Navigate to the “Game Area” by clicking the respective link in the sidebar. Once the game interface loads, you’ll see a multiplier that increases.</li>
              <li><strong>Place Your Bet:</strong> Before the game round begins, enter the amount you wish to bet in the “Bet Amount” field. You can place multiple bets if you wish to do so.</li>
              <li><strong>Monitor the Multiplier:</strong> The multiplier starts at 1.00x and increases. Your goal is to cash out at the highest possible multiplier.</li>
              <li><strong>Cash Out at the Right Moment:</strong> To cash out, click on the “Cash Out” button. The multiplier at the time you cash out will be applied to your bet amount.</li>
              <li><strong>Review Your Bets:</strong> After each round, you can review your bets, cash-outs, and winnings in the “Bets” section of your profile.</li>
            </ol>

            <h4>3. How to Withdraw Funds</h4>
            <ol>
              <li><strong>Log In to Your Account:</strong> Ensure you are logged in to your Crypto Frost account.</li>
              <li><strong>Navigate to the Withdrawal Section:</strong> Go to the “Withdraw” button. Click on it to proceed.</li>
              <li><strong>Enter Withdrawal Details:</strong> Enter the amount you wish to withdraw. Ensure it meets the minimum withdrawal requirement. Provide your BTC wallet address where you wish to receive the funds.</li>
              <li><strong>Submit Withdrawal Request:</strong> Double-check all entered details, especially your wallet address. Click on “Submit Withdrawal Request” to initiate the process.</li>
              <li><strong>Wait for Approval:</strong> Your withdrawal request will be reviewed and processed by the platform. This process may take some time. You will receive a notification once your request is approved, and the funds are sent to your BTC wallet.</li>
              <li><strong>Check Your Wallet:</strong> Once approved, check your BTC wallet to confirm the receipt of funds. The transaction may take a few minutes to appear, depending on network conditions.</li>
            </ol>
          </div>
        );
      case 'terms':
        return (
          <div className="tab-content">
            <h3>Terms and Conditions</h3>
            <div className="terms-content">
              <h3>1. Introduction</h3>
              <p>
                Welcome to Crypto Frost! By accessing or using our platform, you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read these Terms carefully before using our services. If you do not agree to these Terms, you may not access or use our platform.
              </p>

              <h3>2. Eligibility</h3>
              <p>
                By using our platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. You agree to comply with all applicable laws and regulations when using our services.
              </p>

              <h3>3. Account Registration</h3>
              <p>
                <strong>3.1</strong> To use certain features of our platform, you must create an account and provide accurate and complete information during the registration process.
              </p>
              <p>
                <strong>3.2</strong> You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
              </p>
              <p>
                <strong>3.3</strong> You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
              </p>

              <h3>4. Deposits and Withdrawals</h3>
              <p>
                <strong>4.1</strong> All deposits must be made using BTC (Bitcoin) or any other cryptocurrency as specified on the platform.
              </p>
              <p>
                <strong>4.2</strong> Deposits must meet the minimum required amount specified on the deposit page.
              </p>
              <p>
                <strong>4.3</strong> Withdrawal requests must meet the minimum withdrawal amount and comply with our withdrawal policies.
              </p>
              <p>
                <strong>4.4</strong> We reserve the right to delay or cancel any withdrawal request if we suspect fraudulent activity or if the request does not comply with these Terms.
              </p>

              <h3>5. Privacy Policy</h3>
              <p>
                Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.
              </p>

              <h3>6. Intellectual Property</h3>
              <p>
                <strong>6.1</strong> All content, trademarks, and other intellectual property on our platform are owned by or licensed to us.
              </p>
              <p>
                <strong>6.2</strong> You may not use, reproduce, or distribute any content from our platform without our prior written consent.
              </p>

              <h3>7. Limitation of Liability</h3>
              <p>
                <strong>7.1</strong> Our platform is provided "as is" without any warranties of any kind, express or implied.
              </p>
              <p>
                <strong>7.2</strong> We are not liable for any direct, indirect, incidental, or consequential damages arising out of your use of our platform.
              </p>

              <h3>8. Changes to Terms</h3>
              <p>
                <strong>8.1</strong> We reserve the right to update or modify these Terms at any time without prior notice.
              </p>
              <p>
                <strong>8.2</strong> Your continued use of the platform after any changes to these Terms will constitute your acceptance of the new Terms.
              </p>

              <h3>9. Contact Us</h3>
              <p>
                If you have any questions or concerns about these Terms, please contact us on our <a href="https://t.me/cryptofrostcasino" target="_blank" rel="noopener noreferrer">Telegram</a>.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-page">
        {!showSignUp ? (
          <form onSubmit={handleLogin} className="login-form">
            <h2>Login to Your Profile</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
            <p className="toggle-link" onClick={() => setShowSignUp(true)}>
              Don't have an account? Sign up!
            </p>
          </form>
        ) : (
          <form onSubmit={handleSignup} className="login-form">
            <h2>Create an Account</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Sign Up</button>
            <p className="toggle-link" onClick={() => setShowSignUp(false)}>
              Already have an account? Login
            </p>
          </form>
        )}
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="header">
        <h2>Welcome, {username}</h2>
      </div>
      <div className="content">
        <div className="tabs">
          <button onClick={() => setActiveTab('transactions')} className={activeTab === 'transactions' ? 'active' : ''}>
            Transactions
          </button>
          <button onClick={() => setActiveTab('howToPlay')} className={activeTab === 'howToPlay' ? 'active' : ''}>
            How to Play
          </button>
          <button onClick={() => setActiveTab('terms')} className={activeTab === 'terms' ? 'active' : ''}>
            Terms and Conditions
          </button>
        </div>
        <div className="tab-display">
          {renderTabContent()}
        </div>
        <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
      </div>
    </div>
  );
};

export default Profile;
