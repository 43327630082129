import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDnKJeghIS5kWK4NT9goEN2MxAc0TPr30c",
  authDomain: "crypt-f211f.firebaseapp.com",
  projectId: "crypt-f211f",
  storageBucket: "crypt-f211f.appspot.com",
  messagingSenderId: "432724479352",
  appId: "1:432724479352:web:edac30a3080aa6fa8c8c8d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
